var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.showFunction ? _vm.callClickFunction(_vm.showFunction) : true)
    ? _c(
        _vm.is,
        {
          ref: "button",
          tag: "component",
          attrs: {
            to: _vm.goto,
            target: ["ecommerce", "handbook"].includes(_vm.to.name)
              ? "_blank"
              : "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [
          _c(
            "div",
            {
              class: [
                "max-h-32 cursor-pointer rounded-2xl p-4 hover:shadow-md ",
                _vm.iconClasses,
              ],
            },
            [
              _c("fv-icon", {
                class: ["m-auto text-xs"],
                attrs: { icon: _vm.icon, size: "xxl" },
              }),
              _c(
                "h5",
                {
                  staticClass:
                    "mt-1 whitespace-nowrap text-center font-semibold text-gray-500",
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }