interface toDefinition {
  name: string;
}

interface DashboardIconDefinition {
  to: toDefinition;
  title: string;
  showFunction: string | null;
  icon: string;
  iconClasses: string;
}

const DashboardIconDefs: DashboardIconDefinition[] = [
  {
    to: { name: 'ecommerce' },
    title: 'Products',
    showFunction: 'showProductsIcon',
    icon: 'shopping-bag',
    iconClasses: 'text-fv-red-light bg-fv-red-lighter',
  },
  /*{
    to: { name: 'pyramidion' },
    showFunction: 'showPyramidion',
    title: 'Pyramidion',
    icon: 'filter',
    classes: 'text-fv-brownLight bg-fv-brownLighte'
  },*/
  {
    to: {
      name: 'handbook',
    },
    title: 'Handbooks',
    showFunction: 'showHandbook',
    icon: 'frame',
    iconClasses: 'text-fv-blue-light bg-fv-blue-lighter',
  },
  {
    to: {
      name: 'templates',
    },
    title: 'Templates',
    showFunction: null,
    icon: 'consultations',
    iconClasses: 'text-fv-green-light bg-fv-green-lighter',
  },
  {
    to: {
      name: 'support',
    },
    title: 'Support Info',
    showFunction: null,
    icon: 'help',
    iconClasses: 'text-fv-orange-light bg-fv-orange-lighter',
  },
  {
    to: { name: 'map' },
    title: 'Clinic map',
    showFunction: null,
    icon: 'google-maps',
    iconClasses: 'text-fv-map-light bg-fv-map-lighter',
  },
];

export default DashboardIconDefs;
