<template>
  <div class="mx-auto w-full p-4">
    <p class="mb-2 font-display text-4xl font-black text-primary-darker">
      {{ count }}
    </p>
    <p>consultations current month</p>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="postcss"></style>
