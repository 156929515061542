<template>
  <div class="mx-auto w-full p-4">
    <p class="mb-2 font-display text-4xl font-black text-primary-darker">
      {{ journalCount }}
    </p>
    <p>unfinished {{ countText }} current month</p>
  </div>
</template>

<script>
export default {
  props: {
    journalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    countText() {
      return this.journalCount === 1 ? 'journal' : 'journals';
    },
  },
};
</script>

<style lang="postcss"></style>
