<template>
  <div class="rounded-xl bg-white bg-opacity-75 shadow">
    <div
      class="grid grid-cols-2 content-start text-center text-lg font-semibold text-gray-500"
    >
      <button @click="getJournals">
        <div
          :class="[
            'border-b-2 p-3 font-semibold hover:border-action-darker hover:text-blue-700 focus:rounded-b-2xl',
            uiState === 'showJournals'
              ? 'border-action-darker text-blue-700'
              : '',
          ]"
        >
          Unfinished journals
        </div>
      </button>
      <button @click="getReviews(false)">
        <div
          :class="[
            'border-b-2 p-3 font-semibold hover:border-action-darker hover:text-blue-700',
            uiState == 'showFeedback'
              ? 'border-action-darker text-blue-700'
              : '',
          ]"
        >
          Feedback
        </div>
      </button>
    </div>

    <!-- spinner -->
    <div
      v-if="uiState === 'loading'"
      class="grid grid-cols-1 justify-items-center"
    >
      <div class="flex h-60 min-h-full flex-row items-center align-middle">
        <base-spinner :loading="true" size="lg" />
      </div>
    </div>
    <!-- journals -->
    <div
      v-else-if="uiState === 'showJournals'"
      class="flex flex-col content-start"
    >
      <DashboardJournalRow
        v-for="(journal, index) in journals"
        :key="index"
        :journal="journal"
        :class="index == journals.length - 1 ? '' : 'border-b'"
      />
      <div
        v-if="journals.length === 0"
        class="flex flex-row justify-center p-3"
      >
        <div>
          <h2 class="text-gray-500">No unfinished journals</h2>
        </div>
      </div>
    </div>
    <!-- feedback -->
    <div
      v-else-if="uiState === 'showFeedback'"
      class="flex flex-col content-start"
    >
      <DashboardFeedbackRatingRow
        v-for="(rating, index) in ratings"
        :key="index"
        :rating="rating"
      />
      <div v-if="ratings.length === 0" class="flex flex-row justify-center p-3">
        <div>
          <h2 class="text-gray-500">No feedback</h2>
        </div>
      </div>
      <text-button
        v-if="ratings.length !== 0"
        class="w-full p-2 pr-3"
        :to="{ name: 'ratings' }"
      >
        <span
          class="flex w-full items-center justify-end text-lg font-semibold text-blue-700 hover:text-blue-600"
        >
          See all feedback
          <fv-icon
            class="svg-fill inline-block text-blue-700"
            icon="small-chevron-right"
            size="xl"
          />
        </span>
      </text-button>
    </div>
  </div>
</template>

<script>
import { AppointmentApi, RatingApi } from '@/api';
import { mapState } from 'vuex';
import DashboardFeedbackRatingRow from '@/components/dashboard/DashboardFeedbackRatingRow';
import DashboardJournalRow from '@/components/dashboard/DashboardJournalRow';

export default {
  components: {
    DashboardJournalRow,
    DashboardFeedbackRatingRow,
  },
  data() {
    return {
      itemLimit: 5,
      maxCommentLength: 500,
      uiState: 'loading',
      journals: [],
      ratings: [],
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },

  mounted() {
    this.getJournals();
    this.getReviews(true);
  },
  methods: {
    goTo(to) {
      this.$router.push(to);
    },
    async getJournals() {
      if (!this.journals.length) {
        const { data } = await AppointmentApi.fetchJournals({
          journal_status: 0,
          booked: 1,
          limit: this.itemLimit,
        });
        this.journals = data.appointments;
      }
      this.uiState = 'showJournals';
    },
    async getReviews(initialLoad) {
      if (!this.ratings.length) {
        const { data } = await RatingApi.fetch({
          limit: this.itemLimit,
        });
        this.ratings = data.ratings;
      }
      if (!initialLoad) {
        this.uiState = 'showFeedback';
      }
    },
  },
};
</script>

<style scoped></style>
