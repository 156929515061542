<template>
  <div
    :class="[
      'flex cursor-pointer flex-row items-center p-3 hover:bg-beigeLighter',
    ]"
    @click="goToBooking(journal.id)"
  >
    <div class="pl-5 pr-5">
      <fv-icon
        class="svg-fill inline-block text-primary-darker"
        :icon="getIconType(journal.booking.service.key)"
        size="lg"
      />
    </div>
    <div class="w-40 justify-self-start text-gray-500">
      {{ formatTime(journal.booking.booking_datetime) }}
    </div>
    <div class="pr-3">{{ journal.animal.name }}</div>
    <div
      v-if="
        journal.animal &&
        journal.animal.animal_type &&
        journal.animal.animal_type.name
      "
      class="text-gray-500"
    >
      {{ journal.animal.animal_type.name }} - {{ journal.animal.race }}
    </div>
    <div class="ml-auto">
      <fv-icon
        class="svg-fill inline-block text-gray-500"
        icon="small-chevron-right"
        size="xl"
      />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { mapState } from 'vuex';

export default {
  props: {
    journal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    goToBooking(bookingId) {
      this.$router.push({
        name: 'appointment',
        params: {
          id: bookingId,
        },
      });
    },
    formatTime(bookingDatetime, timezone) {
      if (!timezone) {
        timezone = this.user.timezone;
      }

      return format(
        zonedTimeToUtc(bookingDatetime, timezone),
        'yyyy-MM-dd HH:mm'
      );
    },
    getIconType(serviceType) {
      return serviceType === 'veterinary_chat' ? 'chat' : 'video';
    },
  },
};
</script>

<style scoped></style>
