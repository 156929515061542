var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-auto w-full p-4" }, [
    _c(
      "p",
      {
        staticClass:
          "mb-2 font-display text-4xl font-black text-primary-darker",
      },
      [_vm._v(" " + _vm._s(_vm.count) + " ")]
    ),
    _c("p", [_vm._v("consultations current month")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }