var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rounded-xl bg-white bg-opacity-75 shadow" },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-2 content-start text-center text-lg font-semibold text-gray-500",
        },
        [
          _c("button", { on: { click: _vm.getJournals } }, [
            _c(
              "div",
              {
                class: [
                  "border-b-2 p-3 font-semibold hover:border-action-darker hover:text-blue-700 focus:rounded-b-2xl",
                  _vm.uiState === "showJournals"
                    ? "border-action-darker text-blue-700"
                    : "",
                ],
              },
              [_vm._v(" Unfinished journals ")]
            ),
          ]),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.getReviews(false)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "border-b-2 p-3 font-semibold hover:border-action-darker hover:text-blue-700",
                    _vm.uiState == "showFeedback"
                      ? "border-action-darker text-blue-700"
                      : "",
                  ],
                },
                [_vm._v(" Feedback ")]
              ),
            ]
          ),
        ]
      ),
      _vm.uiState === "loading"
        ? _c("div", { staticClass: "grid grid-cols-1 justify-items-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex h-60 min-h-full flex-row items-center align-middle",
              },
              [_c("base-spinner", { attrs: { loading: true, size: "lg" } })],
              1
            ),
          ])
        : _vm.uiState === "showJournals"
        ? _c(
            "div",
            { staticClass: "flex flex-col content-start" },
            [
              _vm._l(_vm.journals, function (journal, index) {
                return _c("DashboardJournalRow", {
                  key: index,
                  class: index == _vm.journals.length - 1 ? "" : "border-b",
                  attrs: { journal: journal },
                })
              }),
              _vm.journals.length === 0
                ? _c(
                    "div",
                    { staticClass: "flex flex-row justify-center p-3" },
                    [_vm._m(0)]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm.uiState === "showFeedback"
        ? _c(
            "div",
            { staticClass: "flex flex-col content-start" },
            [
              _vm._l(_vm.ratings, function (rating, index) {
                return _c("DashboardFeedbackRatingRow", {
                  key: index,
                  attrs: { rating: rating },
                })
              }),
              _vm.ratings.length === 0
                ? _c(
                    "div",
                    { staticClass: "flex flex-row justify-center p-3" },
                    [_vm._m(1)]
                  )
                : _vm._e(),
              _vm.ratings.length !== 0
                ? _c(
                    "text-button",
                    {
                      staticClass: "w-full p-2 pr-3",
                      attrs: { to: { name: "ratings" } },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "flex w-full items-center justify-end text-lg font-semibold text-blue-700 hover:text-blue-600",
                        },
                        [
                          _vm._v(" See all feedback "),
                          _c("fv-icon", {
                            staticClass: "svg-fill inline-block text-blue-700",
                            attrs: { icon: "small-chevron-right", size: "xl" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", { staticClass: "text-gray-500" }, [
        _vm._v("No unfinished journals"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", { staticClass: "text-gray-500" }, [_vm._v("No feedback")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }