<template>
  <router-link
    class="flex cursor-pointer items-center justify-between border-b p-3 py-5 hover:bg-beigeLighter"
    :to="{ name: 'appointment', params: { id: rating.appointmentId } }"
  >
    <div>
      <!-- review header -->
      <div class="flex flex-grow items-center font-semibold">
        <div class="pr-3 pb-1">
          <fv-icon
            class="svg-fill inline-block text-primary-darker"
            :icon="getIconType(rating.service)"
            size="lg"
          />
        </div>
        <div class="pr-3 text-gray-500">
          <span class="pr-3">|</span>
          {{ formatTime(rating.createdAt) }}
        </div>
        <div class="pr-3 text-gray-500">
          <span class="pr-3">|</span>
          {{ rating.customerFirstName }}
        </div>
        <div class="text-gray-500">
          <span class="pr-3">|</span>
          {{ rating.animalName }}
        </div>
      </div>
      <!-- review body -->
      <div class="flex items-center justify-between">
        <div class="max-w-lg pt-1">
          <p class="break-words font-thin">
            <span class="">
              <fv-icon
                v-for="n in 5"
                :key="n"
                class="inline-block h-6 w-6"
                :class="[
                  '-mt-2',
                  n <= rating.rate ? 'text-yellow-300' : 'text-gray-500',
                ]"
                :icon="n <= rating.rate ? 'star-selected' : 'star'"
              />
            </span>
            <span v-if="rating.comment" class="">
              {{
                showComment == rating.id
                  ? rating.comment
                  : trimComment(rating.comment)
              }}
            </span>
            <span
              v-if="rating.comment && rating.comment.length > maxCommentLength"
              class="inline-flex py-2 font-semibold text-fv-blue"
              @click.stop="showMore(rating.id)"
              >{{ showComment == rating.id ? 'Show less' : 'Show more' }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="text-gray-500">
      <fv-icon
        class="svg-fill inline-block text-gray-400"
        icon="small-chevron-right"
        size="xl"
      />
    </div>
  </router-link>
</template>

<script>
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { mapState } from 'vuex';

export default {
  props: {
    rating: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxCommentLength: 500,
      showComment: null,
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    goToBooking(bookingId) {
      this.$router.push({
        name: 'appointment',
        params: {
          id: bookingId,
        },
      });
    },
    trimComment(comment) {
      const index = comment.indexOf(' ', this.maxCommentLength);
      return index === -1 ? comment : comment.substring(0, index) + '...';
    },
    showMore(ratingId) {
      if (ratingId == this.showComment) {
        this.showComment = null;
      } else {
        this.showComment = ratingId;
      }
    },
    formatTime(bookingDatetime, timezone) {
      if (!timezone) {
        timezone = this.user.timezone;
      }

      return format(
        zonedTimeToUtc(bookingDatetime, timezone),
        'yyyy-MM-dd HH:mm'
      );
    },
    getIconType(serviceType) {
      return serviceType === 'veterinary_chat' ? 'chat' : 'video';
    },
  },
};
</script>

<style scoped></style>
