<template>
  <narrow-layout id="dashboard">
    <h2
      class="mb-6 font-display text-5xl font-semibold"
      data-testid="greeting-text"
    >
      Good {{ greeting }}, {{ user.first_name }}!
    </h2>
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-col">
        <!-- stats -->
        <div
          v-if="$can('manage', 'Veterinarian')"
          class="mb-4 grow rounded-xl bg-white bg-opacity-75 shadow"
        >
          <div class="grid max-h-40 grid-cols-3 divide-x py-4 text-center">
            <user-consultations-this-month :count="thisMonth" />
            <user-average-rating :average="averageRating" />
            <user-unfinished-journals :journal-count="unfinishedJournals" />
          </div>
          <div
            class="flex cursor-pointer justify-end p-2 pr-3 text-right text-primary-darker"
          >
            <text-button :to="{ name: 'statistics' }">
              <span
                class="text-lg font-semibold text-blue-700 hover:text-blue-600"
                >My statistics

                <fv-icon
                  class="svg-fill inline-block text-primary-darker"
                  icon="small-chevron-right"
                  size="xl"
                />
              </span>
            </text-button>
          </div>
        </div>

        <!-- admin section -->
        <div
          v-if="$can('manage', 'ClinicListing')"
          class="mx-auto mb-5 w-full rounded-xl bg-white bg-opacity-75 p-5 shadow-md"
        >
          <div class="block">
            <text-button :to="{ name: '2288-dashboard' }">
              <span class="font-semibold text-blue-700 hover:text-blue-600"
                >To 2288 dashboard</span
              >
            </text-button>
          </div>
        </div>

        <!-- journals and feedback -->
        <div class="mb-5 grow">
          <DashboardFeedback />
        </div>
      </div>

      <!-- icons -->
      <div class="ml-4 grid h-full w-2/5 grid-cols-2 gap-x-4 gap-y-4">
        <DashboardCard
          v-for="(iconSetting, index) in DashboardIconDefs"
          :key="index"
          :show-function="iconSetting.showFunction"
          :to="iconSetting.to"
          :title="iconSetting.title"
          :icon="iconSetting.icon"
          :icon-classes="iconSetting.iconClasses"
        />
      </div>
    </div>
  </narrow-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserConsultationsThisMonth from '@/components/user/UserConsultationsThisMonth';
import UserAverageRating from '@/components/user/UserAverageRating';
import UserUnfinishedJournals from '@/components/user/UserUnfinishedJournals';
import DashboardCard from '@/UI/cards/DashboardCard';
import DashboardFeedback from '@/components/dashboard/DashboardFeedback';
import DashboardIconDefs from '@/config/dashboard-icons';
import { AppointmentApi } from '@/api';
import { format, startOfMonth, endOfMonth } from 'date-fns';

export default {
  components: {
    UserConsultationsThisMonth,
    UserAverageRating,
    UserUnfinishedJournals,
    DashboardCard,
    DashboardFeedback,
  },
  data() {
    return {
      DashboardIconDefs,
      thisMonth: 0,
      averageRating: 0,
      unfinishedJournals: 0,
    };
  },

  computed: {
    greeting() {
      let hour = new Date().getHours();
      if (hour < 12) {
        return 'morning';
      } else if (hour < 18) {
        return 'afternoon';
      } else {
        return 'evening';
      }
    },
    ...mapState('user', ['user']),
    ...mapGetters('auth', ['canManageQuestions', 'isAdmin']),
  },

  mounted() {
    this.getWorkedSlots();
  },

  methods: {
    async getWorkedSlots() {
      this.payload = {
        fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
      };

      try {
        const { data } = await AppointmentApi.getStatistics(this.payload);

        this.thisMonth = data.appointmentCount;
        this.averageRating = data.averageRating;
        this.unfinishedJournals = data.unfinishedJournals;
      } catch {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: 'Please try again or submit a support ticket',
        });
      }
    },
  },
};
</script>
