var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass:
        "flex cursor-pointer items-center justify-between border-b p-3 py-5 hover:bg-beigeLighter",
      attrs: {
        to: { name: "appointment", params: { id: _vm.rating.appointmentId } },
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex flex-grow items-center font-semibold" },
          [
            _c(
              "div",
              { staticClass: "pr-3 pb-1" },
              [
                _c("fv-icon", {
                  staticClass: "svg-fill inline-block text-primary-darker",
                  attrs: {
                    icon: _vm.getIconType(_vm.rating.service),
                    size: "lg",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "pr-3 text-gray-500" }, [
              _c("span", { staticClass: "pr-3" }, [_vm._v("|")]),
              _vm._v(" " + _vm._s(_vm.formatTime(_vm.rating.createdAt)) + " "),
            ]),
            _c("div", { staticClass: "pr-3 text-gray-500" }, [
              _c("span", { staticClass: "pr-3" }, [_vm._v("|")]),
              _vm._v(" " + _vm._s(_vm.rating.customerFirstName) + " "),
            ]),
            _c("div", { staticClass: "text-gray-500" }, [
              _c("span", { staticClass: "pr-3" }, [_vm._v("|")]),
              _vm._v(" " + _vm._s(_vm.rating.animalName) + " "),
            ]),
          ]
        ),
        _c("div", { staticClass: "flex items-center justify-between" }, [
          _c("div", { staticClass: "max-w-lg pt-1" }, [
            _c("p", { staticClass: "break-words font-thin" }, [
              _c(
                "span",
                {},
                _vm._l(5, function (n) {
                  return _c("fv-icon", {
                    key: n,
                    staticClass: "inline-block h-6 w-6",
                    class: [
                      "-mt-2",
                      n <= _vm.rating.rate
                        ? "text-yellow-300"
                        : "text-gray-500",
                    ],
                    attrs: {
                      icon: n <= _vm.rating.rate ? "star-selected" : "star",
                    },
                  })
                }),
                1
              ),
              _vm.rating.comment
                ? _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showComment == _vm.rating.id
                            ? _vm.rating.comment
                            : _vm.trimComment(_vm.rating.comment)
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.rating.comment &&
              _vm.rating.comment.length > _vm.maxCommentLength
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inline-flex py-2 font-semibold text-fv-blue",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showMore(_vm.rating.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.showComment == _vm.rating.id
                            ? "Show less"
                            : "Show more"
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "text-gray-500" },
        [
          _c("fv-icon", {
            staticClass: "svg-fill inline-block text-gray-400",
            attrs: { icon: "small-chevron-right", size: "xl" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }