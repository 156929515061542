var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("narrow-layout", { attrs: { id: "dashboard" } }, [
    _c(
      "h2",
      {
        staticClass: "mb-6 font-display text-5xl font-semibold",
        attrs: { "data-testid": "greeting-text" },
      },
      [
        _vm._v(
          " Good " +
            _vm._s(_vm.greeting) +
            ", " +
            _vm._s(_vm.user.first_name) +
            "! "
        ),
      ]
    ),
    _c("div", { staticClass: "flex flex-col lg:flex-row" }, [
      _c("div", { staticClass: "flex flex-col" }, [
        _vm.$can("manage", "Veterinarian")
          ? _c(
              "div",
              {
                staticClass:
                  "mb-4 grow rounded-xl bg-white bg-opacity-75 shadow",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "grid max-h-40 grid-cols-3 divide-x py-4 text-center",
                  },
                  [
                    _c("user-consultations-this-month", {
                      attrs: { count: _vm.thisMonth },
                    }),
                    _c("user-average-rating", {
                      attrs: { average: _vm.averageRating },
                    }),
                    _c("user-unfinished-journals", {
                      attrs: { "journal-count": _vm.unfinishedJournals },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex cursor-pointer justify-end p-2 pr-3 text-right text-primary-darker",
                  },
                  [
                    _c(
                      "text-button",
                      { attrs: { to: { name: "statistics" } } },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-lg font-semibold text-blue-700 hover:text-blue-600",
                          },
                          [
                            _vm._v("My statistics "),
                            _c("fv-icon", {
                              staticClass:
                                "svg-fill inline-block text-primary-darker",
                              attrs: {
                                icon: "small-chevron-right",
                                size: "xl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.$can("manage", "ClinicListing")
          ? _c(
              "div",
              {
                staticClass:
                  "mx-auto mb-5 w-full rounded-xl bg-white bg-opacity-75 p-5 shadow-md",
              },
              [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "text-button",
                      { attrs: { to: { name: "2288-dashboard" } } },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-semibold text-blue-700 hover:text-blue-600",
                          },
                          [_vm._v("To 2288 dashboard")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "mb-5 grow" }, [_c("DashboardFeedback")], 1),
      ]),
      _c(
        "div",
        { staticClass: "ml-4 grid h-full w-2/5 grid-cols-2 gap-x-4 gap-y-4" },
        _vm._l(_vm.DashboardIconDefs, function (iconSetting, index) {
          return _c("DashboardCard", {
            key: index,
            attrs: {
              "show-function": iconSetting.showFunction,
              to: iconSetting.to,
              title: iconSetting.title,
              icon: iconSetting.icon,
              "icon-classes": iconSetting.iconClasses,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }