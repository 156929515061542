<template>
  <div class="mx-auto w-full p-4">
    <p class="mb-2 font-display text-4xl font-black text-primary-darker">
      {{ average.toFixed(1) }}
    </p>
    <p>average rating current month</p>
  </div>
</template>

<script>
export default {
  props: {
    average: {
      type: Number,
      default: 0,
    },
  },
};
</script>
