var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "flex cursor-pointer flex-row items-center p-3 hover:bg-beigeLighter",
      ],
      on: {
        click: function ($event) {
          return _vm.goToBooking(_vm.journal.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pl-5 pr-5" },
        [
          _c("fv-icon", {
            staticClass: "svg-fill inline-block text-primary-darker",
            attrs: {
              icon: _vm.getIconType(_vm.journal.booking.service.key),
              size: "lg",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "w-40 justify-self-start text-gray-500" }, [
        _vm._v(
          " " +
            _vm._s(_vm.formatTime(_vm.journal.booking.booking_datetime)) +
            " "
        ),
      ]),
      _c("div", { staticClass: "pr-3" }, [
        _vm._v(_vm._s(_vm.journal.animal.name)),
      ]),
      _vm.journal.animal &&
      _vm.journal.animal.animal_type &&
      _vm.journal.animal.animal_type.name
        ? _c("div", { staticClass: "text-gray-500" }, [
            _vm._v(
              " " +
                _vm._s(_vm.journal.animal.animal_type.name) +
                " - " +
                _vm._s(_vm.journal.animal.race) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ml-auto" },
        [
          _c("fv-icon", {
            staticClass: "svg-fill inline-block text-gray-500",
            attrs: { icon: "small-chevron-right", size: "xl" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }