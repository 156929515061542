<template>
  <component
    :is="is"
    v-if="showFunction ? callClickFunction(showFunction) : true"
    ref="button"
    :to="goto"
    :target="['ecommerce', 'handbook'].includes(to.name) ? '_blank' : ''"
    @click="$emit('click')"
  >
    <div
      :class="[
        'max-h-32 cursor-pointer rounded-2xl p-4 hover:shadow-md ',
        iconClasses,
      ]"
    >
      <fv-icon :icon="icon" :class="['m-auto text-xs']" size="xxl" />
      <h5
        class="mt-1 whitespace-nowrap text-center font-semibold text-gray-500"
      >
        {{ title }}
      </h5>
    </div>
  </component>
</template>

<script>
import FvIcon from '@/UI/icons/FvIcon';
import { SV_COUNTRY_ID, UK_COUNTRY_ID } from '@/config/countries';
import { mapState } from 'vuex';

export default {
  components: {
    FvIcon,
  },
  props: {
    to: {
      type: [Boolean, String, Object],
      default: false,
    },
    showFunction: {
      type: [Boolean, String],
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('user', ['user']),
    goto() {
      let to = this.to;
      if (['ecommerce', 'handbook'].includes(this.to.name)) {
        to.params = {
          country: this.user.country_id,
        };
        return to;
      }
      return this.to;
    },
    is() {
      return this.to ? 'router-link' : 'button';
    },
  },
  methods: {
    callClickFunction(func) {
      if (func) {
        return this[func]();
      }
    },
    showPyramidion() {
      return [SV_COUNTRY_ID].includes(this.user.country_id);
    },
    showProductsIcon() {
      return [SV_COUNTRY_ID, UK_COUNTRY_ID].includes(this.user.country_id);
    },
    showHandbook() {
      return [SV_COUNTRY_ID].includes(this.user.country_id);
    },
  },
};
</script>

<style scoped></style>
